<template>
  <ContentLoader
    :height="150"
    :width="250"
    :speed="3"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect
      x="40"
      y="7"
      rx="0"
      ry="0"
      width="203"
      height="25"
    />

    <circle cx="17" cy="20" r="15" />

    <rect
      x="40"
      y="45"
      rx="0"
      ry="0"
      width="200"
      height="8"
    />

    <rect
      x="40"
      y="65"
      rx="0"
      ry="0"
      width="200"
      height="8"
    />

    <rect
      x="40"
      y="85"
      rx="0"
      ry="0"
      width="200"
      height="8"
    />

    <rect
      x="40"
      y="105"
      rx="0"
      ry="0"
      width="200"
      height="8"
    />

    <rect
      x="40"
      y="125"
      rx="0"
      ry="0"
      width="200"
      height="8"
    />

    <rect
      x="15.5"
      y="45"
      rx="0"
      ry="0"
      width="3"
      height="90"
    />
  </ContentLoader>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'

import Placeholder from '@/components/placeholders/Placeholder.js'

export default {
  components: {
    ContentLoader
  },

  extends: Placeholder
}
</script>
